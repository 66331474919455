import React from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
// avoid from dexextreme dont render some components

import Context from "./Context";
import LayoutRoute from "./LayoutRoute";
import Student from "../Student";
import Teacher from "../Teacher";

import Extrato from "../Extrato";

import "./App.scss";
require("dotenv").config();

const App = () => {
  return (
    <Router>
      <Context>
        <Switch>
          {/* <Route exact path="/">
						<Login />
					</Route> */}

          {/* <Route exact path="/logout">
						<Logout />
					</Route> */}

          <LayoutRoute exact path="/student">
            <Student />
            <Extrato />
          </LayoutRoute>

          <LayoutRoute exact path="/teacher">
            <Teacher />
            <Extrato />
          </LayoutRoute>
          {/*

					<LayoutRoute exact path="/logout">
						<Account />
					</LayoutRoute>
					<LayoutRoute exact path="/logout">
						<Preferences />
					</LayoutRoute>
					<LayoutRoute exact path="/logout">
						<Help />
					</LayoutRoute> */}
        </Switch>
      </Context>
    </Router>
  );
};

export default App;
