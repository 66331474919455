import { request } from "../Api.utils";

async function fetchReceiver({ id }, token) {
  const response = await request(`/receiver/${id}/verify`, null, "GET", {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  });
  return response;
}
fetchReceiver.meta = {
  wait: true,
  critical: true,
  notify: { success: false, failure: true },
};

async function createReceiver(
  {
    bank_code,
    agencia,
    agencia_dv,
    conta,
    type,
    conta_dv,
    document_number,
    legal_name,
    id,
    email,
  },
  token
) {
  const payload = {
    bank_code,
    agencia,
    agencia_dv,
    conta,
    type,
    conta_dv,
    document_number,
    legal_name,
    id,
    email,
  };

  const response = await request(`/receiver/`, payload, "POST", {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  });
  return response;
}
createReceiver.meta = {
  wait: true,
  critical: true,
  notify: { success: false, failure: true },
};

async function updateReceiver(
  { name, email, id, birthday, phone_number, document },
  token
) {
  const payload = {
    name,
    email,
    id,
    birthday,
    phone_number,
    document,
  };

  const response = await request(`/receiver/`, payload, "POST", {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  });
  return response;
}
updateReceiver.meta = {
  wait: true,
  critical: true,
  notify: { success: false, failure: true },
};

async function createTransference(
  {
    id,
    amount,
    card_id,
    card_holder_name,
    card_expiration_date,
    card_number,
    card_cvv,
    billing,
    items,
  },
  token
) {
  const payload = {
    id,
    amount,
    card_id,
    card_holder_name,
    card_expiration_date,
    card_number,
    card_cvv,
    billing,
    items,
  };

  const response = await request(`/receiver/transference`, payload, "POST", {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  });
  return response;
}
createTransference.meta = {
  wait: true,
  critical: true,
  notify: { success: false, failure: true },
};

async function receiverOccurences({ id, period }, token) {
  const response = await request(
    `/receiver/${id}/ocurrences?period=${period}`,
    null,
    "GET",
    {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  );
  return response;
}
receiverOccurences.meta = {
  wait: true,
  critical: true,
  notify: { success: false, failure: true },
};

export default {
  fetchReceiver,
  createReceiver,
  updateReceiver,
  createTransference,
  receiverOccurences
};
