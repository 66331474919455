import { request } from "../Api.utils";

async function fetchAddress({ cep }) {
  const response = await request(
    `/${cep}/json/unicode/`,
    null,
    "GET",
    {
      "Content-Type": "application/json",
    },
    "https://viacep.com.br/ws"
  );
  return response;
}
fetchAddress.meta = {
  wait: true,
  critical: true,
  notify: { success: false, failure: true },
};

async function fetchUserCards({ id }, token) {
  const response = await request(`/customer/${id}/verify`, null, "GET", {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  });
  return response;
}
fetchUserCards.meta = {
  wait: true,
  critical: true,
  alert: { success: false, failure: true },
};

async function createUserCard(
  {
    id,
    billing,
    card_cvv,
    card_hash,
    card_number,
    card_holder_name,
    card_expiration_date,
  },
  token
) {
  const payload = {
    card_number,
    card_expiration_date,
    card_holder_name,
    card_cvv,
    id,
    card_hash,
    billing,
  };

  const response = await request(`/customer/card`, payload, "POST", {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  });
  return response;
}
createUserCard.meta = {
  wait: true,
  critical: true,
  notify: { success: false, failure: true },
};

async function createCustomer(
  { name, email, id, birthday, phone_number, document },
  token
) {
  const payload = {
    name,
    email,
    id,
    birthday,
    phone_number,
    document,
  };

  const response = await request(`/customer/`, payload, "POST", {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  });

  return response;
}
createCustomer.meta = {
  wait: true,
  critical: true,
  notify: { success: false, failure: true },
};

async function createTransaction(
  {
    id,
    amount,
    card_id,
    card_holder_name,
    card_expiration_date,
    card_number,
    card_cvv,
    billing,
    items,
  },
  token
) {
  const payload = {
    id,
    amount,
    card_id,
    card_holder_name,
    card_expiration_date,
    card_number,
    card_cvv,
    billing,
    items,
  };

  const response = await request(`/customer/transaction`, payload, "POST", {
    "Content-Type": "application/json",
    Authorization: `Bearer ${token}`,
  });
  return response;
}
createTransaction.meta = {
  wait: true,
  critical: true,
  notify: { success: false, failure: true },
};

async function customerOccurences({ id, period }, token) {
  const response = await request(
    `/customer/${id}/ocurrences?period=${period}`,
    null,
    "GET",
    {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    }
  );
  return response;
}
customerOccurences.meta = {
  wait: true,
  critical: true,
  notify: { success: false, failure: true },
};

export default {
  fetchUserCards,
  createUserCard,
  createCustomer,
  createTransaction,
  customerOccurences,
  fetchAddress,
};
