import React from "react";
import PropTypes from "prop-types";

import ApiContext from "../../../providers/ApiContext";
import SessionContext from "../../../providers/SessionContext";

const Context = (props) => {
  const { children } = props;

  return (
    <ApiContext>
      <SessionContext>{children}</SessionContext>
    </ApiContext>
  );
};

Context.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Context;
