import Cookies from 'js-cookie'

const Storage = {
	get: name => {
		return Cookies.getJSON(name) || Cookies.get(name)
	},

	set: (name, value, expires) => {
		const options = expires && { expires }
		return Cookies.set(name, value, options)
	},

	remove: name => {
		return Cookies.remove(name)
	},

	removeAll: () => {
		Object.keys(Cookies.get()).forEach(function (cookieName) {
			Cookies.remove(cookieName)
		})
	},
}

Object.freeze(Storage)
export default Storage
