import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";

import Storage from "../../providers/Storage";
import { ApiContext } from "../../providers/ApiContext";

const initialState = {
  ...Storage.get("token"),
  ...Storage.get("user"),
};

const SessionContext = createContext({});
SessionContext.displayName = "Session";

const SessionProvider = (props) => {
  const Api = useContext(ApiContext);
  const [state, setState] = useState(initialState);

  const { pathname, search } = useLocation();
  const history = useHistory();

  const { children } = props;

  async function submitLogin({ rememberMe, ...payload }) {
    try {
      const result = await Api.submitLogin(payload);
      setState(result);

      const { modules, menuItems, customTerms, ...session } = result;
      const expires = rememberMe && 30;
      Storage.set("modules", { modules }, expires);
      Storage.set("menuItems", { menuItems }, expires);
      Storage.set("session", session, expires);
      Storage.set("username", session.username.toLowerCase());

      // TODO
      //Storage.set('customTerms', { ...customTerms }, expires)
      localStorage.setItem("customTerms", JSON.stringify(customTerms));
    } catch (error) {
      throw error;
    }
  }

  function forceStore(user, token) {
    Storage.set("user", user);
    Storage.set("token", token);
  }

  async function doLogout() {
    let theme = Storage.get("dx-theme");
    try {
      await Api.submitLogout(state);
    } catch (error) {
      throw error;
    } finally {
      Storage.removeAll();
      setState({});
    }
  }

  function isUserLoggedIn() {
    return Api.isConnectedToServer && !state;
  }

  useEffect(() => {
    console.log("opa", search);
    if (search) {
      const params = new URLSearchParams(search);

      let user = params.get("user");
      let token = params.get("to");

      forceStore(user, token);

      setState({
        user,
        token,
      });

      params.delete("user");
      params.delete("to");

      history.replace({
        search: params.toString(),
      });
    } else {
      let user = Storage.get("user");
      let token = Storage.get("token");
      setState({
        user,
        token,
      });
    }
  }, [history, search]);

  const context = {
    submitLogin,
    doLogout,
    getCredentials: () => state,
    getUsername: () => state.username,
  };

  return (
    <SessionContext.Provider value={context}>
      {children}
    </SessionContext.Provider>
  );
};

SessionProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SessionContext, SessionProvider as default };
