import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Route } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

// import { Layout } from '../../Layout'

const LayoutRoute = (props) => {
  const { children, ...routeProps } = props;

  const Layout = lazy(() =>
    import("../../Layout").then((module) => ({ default: module.Layout }))
  );

  return (
    <Suspense
      fallback={
        <Spinner animation="border" role="status">
          <span className="visually-hidden">Carregando...</span>
        </Spinner>
      }
    >
      <Route {...routeProps}>
        <Layout>{children}</Layout>
      </Route>
    </Suspense>
  );
};

LayoutRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutRoute;
