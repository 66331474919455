import React, { createContext, useState, useReducer } from "react";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";

import Api from "./Api";
import { withSwalInstance } from "sweetalert2-react";
import swal from "sweetalert2";

const SweetAlert = withSwalInstance(swal);

const ApiContext = createContext({});
ApiContext.displayName = "Api";

let answerYes, answerNo;

const ApiProvider = (props) => {
  const [loadingCount, toggleLoading] = useReducer(loadCounter);
  const [dialogProps, setDialogBoxProps] = useState(null);
  const [isConnectedToServer, toggleConnectionToServer] = useState(true);
  const [showReloadPage, setShowReloadPage] = useState(false);

  function showDialogBox(type, errorOrMsg) {
    let logs = {
      error: {
        position: "top-end",
        icon: "error",
        title: "Erro",
        text: errorOrMsg || "Erro ao buscar dados",
      },
    };
    setDialogBoxProps(logs[type]);
  }

  function showToast(type, message) {
    let logs = {
      error: {
        position: "top-end",
        icon: "error",
        title: "Erro",
        text: "Erro ao buscar dados",
      },
    };
    const options = logs[type];
    // notify(options, type, NOTIFY_TIME);
  }

  function submitAnswer(yes) {
    setDialogBoxProps(null);
    if (yes) {
      answerYes();
    } else {
      answerNo();
    }
  }

  function onFetchSuccess(meta) {
    if (!meta.critical) {
      setShowReloadPage(false);
    }

    if ((!meta.public || meta.login) && !isConnectedToServer) {
      toggleConnectionToServer(true);
    }
    if (meta.notify && meta.notify.success) {
      // showToast(SUCCESS, SUCCESS_MESSAGE)
    } else if (meta.alert && meta.alert.success) {
      // showDialogBox('alert', SUCCESS_MESSAGE)
    }
    toggleLoading(false);
  }

  function onFetchFailure(meta, error) {
    if (meta.critical) {
      setShowReloadPage(true);
    }
    if (!meta.public && !meta.login) {
      // const isConnected = !RELOG_ERROR_CODES.includes(error.valueOf())
      // toggleConnectionToServer(isConnected)
      toggleLoading(false);
    }

    if (meta.notify && meta.notify.failure) {
      showToast("ERROR", error.toString());
    } else if (meta.alert && meta.alert.failure) {
      showDialogBox("error", error);
    }
    toggleLoading(false);
  }

  const context = {
    isConnectedToServer,
    showDialogBox,
    showToast,
  };

  // Pass every Api method through this “middleware” to detect when connection is lost.
  for (const methodName in Api) {
    context[methodName] = async (...args) => {
      const method = Api[methodName];

      try {
        toggleLoading(method.meta.wait);
        let response;
        const saveResponse = method.meta.store;
        const keepResponseTime = method.meta.storeTime || 7;
        if (saveResponse) {
          const { process = 0 } = args[0];
          const storedData = Storage.get(`${process}`);
          if (storedData !== undefined && !isEmpty(storedData)) {
            response = storedData;
          } else {
            Storage.remove(`${process}`);
            response = await method(...args);
            Storage.set(`${process}`, response, keepResponseTime);
          }
        } else {
          response = await method(...args);
        }
        onFetchSuccess(method.meta);
        return response;
      } catch (error) {
        onFetchFailure(method.meta, error);
        throw error;
      }
    };
  }

  const { children } = props;
  const isLoading = loadingCount > 0;
  const delay = loadingCount > 1 ? 0 : 200; // Avoid blinking when showing the second panel.
  const canShowDialogBox = dialogProps !== null;

  if (isLoading) {
    swal.showLoading();
  } else {
    swal.close();
  }

  return (
    <ApiContext.Provider value={context}>
      {/* {(isLoading && (
				<DxLoadPanel shadingColor={SHADING_COLOR} delay={delay} visible />
			)) ||
				(canShowDialogBox && <Dialog {...dialogProps} submitAnswer={submitAnswer} />)}
			{showReloadPage && <ReloadMessage />} */}

      <SweetAlert
        show={canShowDialogBox}
        timerProgressBar={true}
		{...dialogProps}
      />
      {children}
    </ApiContext.Provider>
  );
};

ApiProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ApiContext, ApiProvider as default };

function loadCounter(counter = 0, toggle) {
  const total = toggle ? counter + 1 : counter - 1;
  return total < 0 ? 0 : total;
}
