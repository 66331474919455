import React, { useContext, useState, useCallback } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Table from "react-bootstrap/Table";

import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";

import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import FormControl from "react-bootstrap/FormControl";

import Badge from "react-bootstrap/Badge";

import InputMask from "react-input-mask";

import Button from "react-bootstrap/Button";

import { ApiContext } from "../../providers/ApiContext";
import { SessionContext } from "../../providers/SessionContext";

import visa from "./__assets__/visa.png";
import master from "./__assets__/mastercard.png";

const Student = () => {
  const Api = useContext(ApiContext);
  const Session = useContext(SessionContext);

  const [show, setShow] = useState(false);
  const [transaction, toggleTransaction] = useState(false);

  const [cards, setCards] = useState(null);

  // New card form
  const [card_number, setNumber] = useState("");
  const [holder_name, setHolder] = useState("");
  const [validity, setValidity] = useState("");
  const [security_code, setCode] = useState("");
  // new card form invoice data
  const [addressStreet, setAddressStreet] = useState("");
  const [addressNumber, setAddressNumber] = useState("");
  const [addressNeighborhood, setAddressNeighborhood] = useState("");
  const [addressCity, setAddressCity] = useState("");
  const [addressState, setAddressState] = useState("");
  const [addressZip, setAddressZip] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function fetchUSerData() {
    try {
      const { user, token } = Session.getCredentials();

      console.log(user, token);
      const [cardsResponse] = await Promise.all([
        Api.fetchUserCards({ id: user }, token),
      ]);
      const { cards } = cardsResponse;
      console.log(cards);
      if (cards.length > 0) {
        setCards(cards);
      } else {
        handleShow();
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function fetchCep(value) {
    try {
      console.log(value);
      let cep = value.replace(/\D/g, "");
      if (cep.length === 8) {
        const [cardsResponse] = await Promise.all([Api.fetchAddress({ cep })]);

        console.log(cardsResponse);

        const { logradouro, bairro, localidade, uf } = cardsResponse;

        setAddressStreet(logradouro);
        setAddressNeighborhood(bairro);
        setAddressCity(localidade);
        setAddressState(uf);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmitForm = () => {
    const card = {
      card_holder_name: holder_name,
      card_expiration_date: validity,
      card_number: card_number.trim(),
      card_cvv: security_code,
    };

    const { user, token } = Session.getCredentials();
    /*global pagarme*/
    pagarme.client
      .connect({
        encryption_key:
          "ek_live_uoA6N8SKfTm39PQg8MuvVWMz1pnhrj",
      })
      .then((client) => client.security.encrypt(card))
      .then(async (card_hash) => {
        const response = await Api.createCustomer(
          {
            name: null,
            email: null,
            id: user,
            birthday: null,
            phone_number: null,
            document: null,
          },
          token
        );

        const { id: userId } = response;

        await Api.createUserCard(
          {
            card_number: card_number.trim(),
            card_expiration_date: validity.replace("/", ""),
            card_holder_name: holder_name,
            card_cvv: security_code,
            id: userId,
            card_hash: card_hash,
            billing: {
              address: {
                country: "br",
                state: addressState,
                city: addressCity,
                neighborhood: addressNeighborhood,
                street: addressStreet,
                street_number: addressNumber,
                zipcode: addressZip.replace("-", ""),
              },
            },
          },
          token
        );
        toggleTransaction(true);
      });
  };

  const fetchInitialData = useCallback(fetchUSerData, [Api, Session]);

  React.useEffect(() => {
    if (!cards) fetchInitialData();
  }, []);

  const brands = {
    visa: <img src={visa} alt="Cartão visa" height="35px" />,
    mastercard: <img src={master} alt="Cartão masrtercard" height="25px" />,
  };

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <ModalHeader closeButton>
          {!transaction && <ModalTitle>Cadastre seu cartão</ModalTitle>}
          {transaction && <ModalTitle>Perfeito!</ModalTitle>}
        </ModalHeader>
        <ModalBody>
          {transaction && (
            <Row className="mb-3">
              <Col>
                <h4>
                  Dados salvos com sucesso, Agora, volte para no app e realize a
                  matrícula.
                </h4>
              </Col>
            </Row>
          )}

          {!transaction && (
            <Form>
              <Row className="mb-3">
                <Col>
                  <h4>Dados de pagamento</h4>
                </Col>
              </Row>
              <Row className="mb-3">
                <FormGroup as={Col} controlId="cardNumber">
                  <FormLabel>Número do cartão</FormLabel>
                  <InputMask
                    mask="9999 9999 9999 9999"
                    onChange={({ target: { value } }) => setNumber(value)}
                    value={card_number}
                  >
                    <FormControl type="text" placeholder="Número do cartão" />
                  </InputMask>
                </FormGroup>
              </Row>

              <Row className="mb-3">
                <FormGroup as={Col} controlId="holderName">
                  <FormLabel>Nome igual no cartão</FormLabel>
                  <FormControl
                    type="text"
                    placeholder="Nome igual no cartão"
                    value={holder_name}
                    onChange={({ target: { value } }) => setHolder(value)}
                  />
                </FormGroup>
              </Row>

              <Row className="mb-3">
                <FormGroup as={Col} controlId="validity">
                  <FormLabel>Validade</FormLabel>
                  <InputMask
                    mask="99/99"
                    value={validity}
                    onChange={({ target: { value } }) => setValidity(value)}
                  >
                    <FormControl type="text" placeholder="Validade" />
                  </InputMask>
                </FormGroup>

                <FormGroup as={Col} controlId="code">
                  <FormLabel>CVV</FormLabel>
                  <FormControl
                    type="number"
                    placeholder="CVV"
                    max="3"
                    value={security_code}
                    onChange={({ target: { value } }) => setCode(value)}
                  />
                </FormGroup>
              </Row>

              <FormGroup className="mb-3" controlId="addressZip">
                <FormLabel>CEP</FormLabel>
                <InputMask
                  mask="99999-999"
                  value={addressZip}
                  onChange={({ target: { value } }) => {
                    setAddressZip(value);
                    fetchCep(value);
                  }}
                >
                  <FormControl placeholder="CEP" />
                </InputMask>
              </FormGroup>

              <FormGroup className="mb-3" controlId="street">
                <FormLabel>Logradouro</FormLabel>
                <FormControl
                  placeholder="Logradouro"
                  value={addressStreet}
                  onChange={({ target: { value } }) => setAddressStreet(value)}
                />
              </FormGroup>
              <FormGroup className="mb-3" controlId="addressNumber">
                <FormLabel>Número</FormLabel>
                <FormControl
                  placeholder="Número"
                  value={addressNumber}
                  onChange={({ target: { value } }) => setAddressNumber(value)}
                />
              </FormGroup>
              <FormGroup className="mb-3" controlId="addressNeighborhood">
                <FormLabel>Bairro</FormLabel>
                <FormControl
                  placeholder="Bairro"
                  value={addressNeighborhood}
                  onChange={({ target: { value } }) =>
                    setAddressNeighborhood(value)
                  }
                />
              </FormGroup>
              <FormGroup className="mb-3" controlId="addressCity">
                <FormLabel>Cidade</FormLabel>
                <FormControl
                  placeholder="Cidade"
                  value={addressCity}
                  onChange={({ target: { value } }) => setAddressCity(value)}
                />
              </FormGroup>
              <FormGroup className="mb-3" controlId="addressState">
                <FormLabel>UF</FormLabel>
                <FormControl
                  placeholder="UF"
                  value={addressState}
                  onChange={({ target: { value } }) => setAddressState(value)}
                />
              </FormGroup>
            </Form>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={() => {
              handleClose();
              toggleTransaction(!transaction);
            }}
          >
            Fechar
          </Button>
          {!transaction && (
            <Button
              variant="primary"
              onClick={handleSubmitForm}
              style={{ backgroundColor: "#00a3f4" }}
            >
              Salvar
            </Button>
          )}
        </ModalFooter>
      </Modal>

      <Row className="justify-content-between mb-3 mt-5">
        <Col xs={8} sm={8} md={10} lg={4}>
          <h4>Meus cartões</h4>
        </Col>
        <Col xs={4} sm={4} md={2} lg={4}>
          <Button
            variant="primary"
            type="button"
            onClick={handleShow}
            style={{ backgroundColor: "#00a3f4" }}
          >
            Novo
          </Button>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <Table striped={false} bordered={false} hover>
            <thead>
              <tr>
                <th>Bandeira</th>
                <th>Final</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {cards &&
                cards.map((card, i) => (
                  <tr key={i}>
                    <td>{brands[card.brand]}</td>
                    <td>Final: {card.last_digits} </td>
                    {card.active && (
                      <td>
                        <div>
                          <Badge bg="success">Principal</Badge>
                        </div>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
          <hr style={{ marginTop: "50px" }} />
        </Col>
      </Row>
    </div>
  );
};

export default Student;
