import React, { useContext, useState, useCallback } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";

import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import FormControl from "react-bootstrap/FormControl";
import FormSelect from "react-bootstrap/FormSelect";

import InputMask from "react-input-mask";

import Button from "react-bootstrap/Button";
import { useLocation, useParams } from "react-router-dom";

import { ApiContext } from "../../providers/ApiContext";
import { SessionContext } from "../../providers/SessionContext";

import banks from "./banks.json";

const Student = () => {
  const Api = useContext(ApiContext);
  const Session = useContext(SessionContext);

  const [show, setShow] = useState(false);
  const [transaction, toggleTransaction] = useState(false);
  const [error, toggleError] = useState(false);

  const [active, toggleActive] = useState(false);

  const [account, setAccount] = useState();

  // New card form
  const [userName, setName] = useState("");
  const [userEmail, setEmail] = useState("");

  const [userDocument, setDocument] = useState("");
  const [documentMask, setDocumentMask] = useState("999.999.999-99");

  const [accountType, setAccountType] = useState("");
  const [accountBank, setAccountBank] = useState("");
  const [accountAgency, setAccountAgency] = useState("");
  const [accountAgencyCode, setAccountAgencyCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [accountNumberCode, setAccountNumberCode] = useState("");

  const [userId, setId] = useState("");

  const param = useParams();

  const search = useLocation().search;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function fetchUSerData() {
    try {
      const { user, token } = Session.getCredentials();

      const [receiverResponse] = await Promise.all([
        Api.fetchReceiver({ id: user }, token),
      ]);
      const { receiver } = receiverResponse;
      const {
        agencia,
        agencia_dv,
        bank_code,
        conta,
        conta_dv,
        document_number,
        legal_name,
        type,
        email,
      } = receiver;
      setName(legal_name);
      setEmail(email);
      setDocument(document_number);
      // setDocumentMask();
      setAccountType(type);
      setAccountBank(bank_code);
      setAccountAgency(agencia);
      setAccountAgencyCode(agencia_dv || "");
      setAccountNumber(conta);
      setAccountNumberCode(conta_dv);
      toggleActive(conta_dv !== null);
      setAccount(receiver);
    } catch (error) {
      console.error(error);
    }
  }

  const handleSubmitForm = async () => {
    try {
      const { user, token } = Session.getCredentials();

      const response = await Api.createReceiver(
        {
          bank_code: accountBank,
          agencia: accountAgency,
          agencia_dv: accountAgencyCode || null,
          conta: accountNumber,
          type: accountType,
          conta_dv: accountNumberCode,
          document_number: String(userDocument)
            .replace(/\./g, "")
            .replace("-", "")
            .replace("/", ""),
          legal_name: userName,
          id: user,
          email: userEmail,
        },
        token
      );
      fetchUSerData();
      toggleError(false);
      toggleTransaction(true);
      handleShow();
    } catch (error) {
      toggleError(true);
    }
  };

  const fetchInitialData = useCallback(fetchUSerData, [param[0], userId]);

  React.useEffect(() => {
    const params = new URLSearchParams(search); // id=123
    console.log(account);
    let id = params.get("user"); // 123
    setId(id);
    let empty = params.get("empty"); // 123
    if (!account && empty !== "true") {
      fetchInitialData();
    }
  }, [account]);

  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <ModalHeader closeButton>
          {!error && <ModalTitle>Confirmado!</ModalTitle>}
          {error && <ModalTitle>Ops!</ModalTitle>}
        </ModalHeader>
        <ModalBody>
          {error && (
            <Row className="mb-3">
              <Col>
                <h4>Dados bancários inválidos. Por favor, tente novamente.</h4>
              </Col>
            </Row>
          )}

          {!error && (
            <Row className="mb-3">
              <Col>
                <h4>Dados salvos com sucesso</h4>
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={handleClose}
            style={{ backgroundColor: "#00a3f4" }}
          >
            Fechar
          </Button>
        </ModalFooter>
      </Modal>

      <Row className="mb-3 mt-5">
        <Col>
          <Form>
            <Row className="mb-3">
              <Col>
                <h4>Dados pessoais</h4>
              </Col>
            </Row>
            <Row className="mb-3">
              <FormGroup as={Col} controlId="name">
                <FormLabel>Nome completo</FormLabel>
                <FormControl
                  type="text"
                  placeholder="Nome completo"
                  disabled={active}
                  value={userName}
                  onChange={({ target: { value } }) => setName(value)}
                />
              </FormGroup>
            </Row>
            <Row className="mb-3">
              <FormGroup as={Col} controlId="email">
                <FormLabel>Email</FormLabel>
                <FormControl
                  type="text"
                  placeholder="Email"
                  disabled={active}
                  value={userEmail}
                  onChange={({ target: { value } }) => setEmail(value)}
                />
              </FormGroup>
            </Row>

            <Row className="mb-3">
              <FormGroup as={Col} controlId="userDocument">
                <FormLabel>CPF ou CNPJ</FormLabel>
                <InputMask
                  mask={documentMask}
                  onChange={({ target: { value } }) => {
                    setDocument(value);
                  }}
                  disabled={active}
                  value={userDocument}
                >
                  <FormControl type="text" placeholder="CPF ou CNPJ" />
                </InputMask>
              </FormGroup>
            </Row>
            <Row className="mb-3 mt-5">
              <Col>
                <h4>Dados bancários para recebimento</h4>
              </Col>
            </Row>

            <Row className="mb-3">
              <FormGroup as={Col} controlId="accountType">
                <FormLabel>Tipo de conta</FormLabel>
                <FormSelect
                  aria-label="Tipo de conta"
                  value={accountType}
                  onChange={({ target: { value } }) => setAccountType(value)}
                  disabled={active}
                >
                  <option>Selecione...</option>
                  <option value="conta_corrente">Corrente</option>
                  <option value="conta_poupanca">Poupança</option>
                  <option value="conta_corrente_conjunta">Corrente conjunta</option>
                  <option value="conta_poupanca_conjunta">Poupança conjunta</option>
                </FormSelect>
              </FormGroup>
            </Row>
            <Row className="mb-3">
              <FormGroup as={Col} controlId="accountBank">
                <FormLabel>Código do banco</FormLabel>
                <FormSelect
                  aria-label="Código do banco"
                  value={accountBank}
                  onChange={({ target: { value } }) => setAccountBank(value)}
                  disabled={active}
                >
                  <option>Selecione...</option>
                  {banks.map((bank, i) => (
                    <option key={i} value={bank.value}>
                      {bank.label}
                    </option>
                  ))}
                </FormSelect>
              </FormGroup>
            </Row>

            <Row className="mb-3">
              <FormGroup as={Col} controlId="accountAgency">
                <FormLabel>Agência</FormLabel>
                <FormControl
                  type="text"
                  placeholder="Agência"
                  value={accountAgency}
                  disabled={active}
                  onChange={({ target: { value } }) => setAccountAgency(value)}
                />
              </FormGroup>

              <FormGroup as={Col} controlId="accountAgencyCode">
                <FormLabel>Ag Dígito</FormLabel>
                <FormControl
                  type="text"
                  placeholder="Ag Dígito"
                  value={accountAgencyCode}
                  onChange={({ target: { value } }) =>
                    setAccountAgencyCode(value)
                  }
                  disabled={active}
                />
              </FormGroup>
            </Row>
            <Row className="mb-3">
              <FormGroup as={Col} controlId="accountNumber">
                <FormLabel>Conta</FormLabel>
                <FormControl
                  type="text"
                  placeholder="Conta"
                  value={accountNumber}
                  onChange={({ target: { value } }) => setAccountNumber(value)}
                  disabled={active}
                />
              </FormGroup>

              <FormGroup as={Col} controlId="accountNumberCode">
                <FormLabel>Conta dígito</FormLabel>
                <FormControl
                  type="text"
                  placeholder="Conta dígito"
                  value={accountNumberCode}
                  onChange={({ target: { value } }) =>
                    setAccountNumberCode(value)
                  }
                  disabled={active}
                />
              </FormGroup>
            </Row>
          </Form>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <div className="d-grid gap-2">
            <Button
              variant="primary"
              size="lg"
              onClick={() =>
                account && active ? toggleActive(!active) : handleSubmitForm()
              }
              style={{ backgroundColor: "#00a3f4" }}
            >
              {account && active ? "Editar" : "Salvar"}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Student;
