import AccessApi from './AccessApi'
import ListingApi from './ListingApi'
import StudentApi from './StudentApi'
import TeacherApi from './TeacherApi'


const Api = {
	...AccessApi,
	...TeacherApi,
	...StudentApi,
}

Object.freeze(Api)
export default Api
