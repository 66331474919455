export async function request(
  path,
  body,
  method = "POST",
  headers = null,
  url
) {
  // TODO get api env from .env and concat
  const apiUrl = url
    ? url
    : "https://devapifinanceiro.turnoinverso.com.br/v1" +
      path;
  // "http://localhost:5000/v1" + path;

  const fetchSettings = {
    method: method,
    body: body ? JSON.stringify(body) : null,
    mode: "no-cors",
  };
  if (headers) {
    let h = new Headers();
    Object.keys(headers).forEach((key) => h.set(key, headers[key]));
    Object.assign(fetchSettings, { headers: h, mode: "cors" });
  }

  try {
    const fetchData = await fetch(apiUrl, fetchSettings);

    switch (fetchData.status) {
      case 401:
        throw new Error("Não autorizado!");
      default:
        return await fetchData.json();
    }
  } catch (error) {
    console.log(error);
    // TODO:
    throw error;
  }
}

export function checkInternetAvailable() {
  return window.navigator.onLine;
}

export function checkProtocol() {
  return window.location.protocol !== "http:";
}
