import React, { useCallback, useContext, useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Table from "react-bootstrap/Table";

import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import FormLabel from "react-bootstrap/FormLabel";
import FormControl from "react-bootstrap/FormControl";
import FormSelect from "react-bootstrap/FormSelect";

import Button from "react-bootstrap/Button";
import { ApiContext } from "../../providers/ApiContext";
import { SessionContext } from "../../providers/SessionContext";

import { useLocation, useParams } from "react-router-dom";
import * as XLSX from "xlsx";

const daysOfWeek = {
  wednesday: "qua",
  thursday: "qui",
  friday: "sex",
  sun: "dom",
  sat: "sáb",
  monday: "seg",
  tuesday: "ter",
};

const months = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Jul",
  "Ago",
  "Set",
  "Out",
  "Nov",
  "Dex",
];

const Extrato = () => {
  const Api = useContext(ApiContext);
  const Session = useContext(SessionContext);

  const [userType, setType] = useState(null);
  const [balance, setBalance] = useState(null);

  const [period, setPeriod] = useState(new Date().getMonth()-1);
  const [occurrences, setOccurrences] = useState(null);
  const [files, setFiles] = useState(null);

  const location = useLocation();

  async function fetchUSerData() {
    try {
      const { user, token } = Session.getCredentials();

      console.log(user, token);

      const { pathname } = location;

      if (pathname === "/teacher") {
        const [receiverResponse, occurrenceResponse] = await Promise.all([
          Api.fetchReceiver({ id: user }, token),
          Api.receiverOccurences({ id: user, period: period }, token),
        ]);
        const { balance } = receiverResponse;
        const { occurrencies, file } = occurrenceResponse;
        console.log(occurrencies);
        setOccurrences(occurrencies);
        setFiles(file);
        console.log(balance);
        setBalance(balance);
        setType("T");
      } else {
        const [CustomerResponse] = await Promise.all([
          Api.customerOccurences({ id: user, period: period }, token),
        ]);
        const { occurrencies, file } = CustomerResponse;
        console.log(occurrencies);
        setOccurrences(occurrencies);
        setFiles(file);
        setType("S");
      }
    } catch (error) {
      console.error(error);
    }
  }

  function createFile() {
    var workbook = XLSX.utils.book_new();

    let data = [];

    for (let occ of files) {
      let { stdname, last_name, datetime, name, times, weekday, value } = occ;

      console.log(datetime);

      for (let time of times) {
        data.push({
          ...(stdname
            ? {
                Nome: stdname + " " + last_name,
              }
            : {}),
          "Nome da aula": name,
          "Dia da semana": daysOfWeek[weekday],
          "Hora da aula": time,
          "Data da aula": `${new Date(datetime).getDate()}/${
            new Date(datetime).getMonth() + 1
          }/${new Date(datetime).getFullYear()}`,
          Valor: Number(value).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          }),
        });
      }
    }
    let wb = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, wb, "Minhas aulas");

    var wbout = XLSX.write(workbook, {
      bookType: "xlsx",
      bookSST: false,
      type: "array",
    });
    XLSX.writeFile(workbook, "extrato_turno_inverso.xlsx");
  }

  const fetchInitialData = useCallback(fetchUSerData, [
    Api,
    Session,
    location,
    period,
  ]);

  React.useEffect(() => {
    if (!userType) fetchInitialData();
  }, [userType]);

  React.useEffect(() => {
    if (userType && period) fetchInitialData();
  }, [period]);

  return (
    <div>
      <Form>
        {userType === "T" && (
          <Row className="mb-3 mt-5">
            <Col>
              <h4>Seu saldo</h4>
            </Col>
          </Row>
        )}
        {userType === "T" && (
          <Row className="mb-3 mt-3">
            <Col xs={12} sm={8} md={10} lg={4}>
              <FormGroup as={Row} controlId="email">
                <FormLabel column>Disponível para saque</FormLabel>
                <Col>
                  <FormControl
                    type="text"
                    value={(
                      Number(balance?.available?.amount || 0) / 100
                    ).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                    disabled
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
        )}
        {userType === "T" && (
          <Row className="mb-3">
            <Col xs={12} sm={8} md={10} lg={4}>
              <FormGroup as={Row} controlId="email">
                <FormLabel column>A receber</FormLabel>
                <Col>
                  <FormControl
                    type="text"
                    value={(
                      Number(balance?.waiting_funds?.amount || 0) / 100
                    ).toLocaleString("pt-BR", {
                      style: "currency",
                      currency: "BRL",
                    })}
                    disabled
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
        )}
        {userType === "T" && (
          <Row className="mb-3 justify-content-center">
            <Col xs={12} sm={8} md={10} lg={4}>
              <div className="d-grid gap-2">
                <Button
                  variant="primary"
                  size="lg"
                  style={{ backgroundColor: "#00a3f4" }}
                >
                  Sacar valor disponível
                </Button>
              </div>
            </Col>
          </Row>
        )}
        {balance && <hr />}
        <Row className="mb-3 mt-5">
          <Col>
            <h4>Seu extrato</h4>
          </Col>
        </Row>

        <Row className="mb-3 mt-3">
          <Col xs={8} sm={8} md={10} lg={4}>
            <FormGroup as={Row} controlId="formGridState">
              <FormLabel column>Selecione o período</FormLabel>
              <Col>
                <FormSelect
                  defaultValue={new Date().getMonth() - 1}
                  onChange={({ target: { value } }) => setPeriod(value)}
                >
                  <option>Selecione...</option>
                  {new Array(3)
                    .fill(new Date().getMonth() - 1)
                    .map((e, i) => (
                      <option key={i} value={e - i}>
                        {months[e - i]}/21
                      </option>
                    ))
                    .reverse()}
                </FormSelect>
              </Col>
            </FormGroup>
          </Col>
          <Col xs={4} sm={4} md={2} lg={4}>
            <Button
              variant="primary"
              type="button"
              style={{ backgroundColor: "#00a3f4" }}
              onClick={() => {
                fetchInitialData();
              }}
            >
              Buscar
            </Button>
          </Col>
        </Row>
        {occurrences && (
          <Row className="mb-3">
            <Col>
              <Table striped={false} bordered={false} hover>
                <thead>
                  <tr>
                    {userType === "T" && <th>Aluno</th>}
                    <th>Atividade</th>
                    <th>Pago</th>
                  </tr>
                </thead>
                <tbody>
                  {occurrences.map((occ, i) => (
                    <tr key={i}>
                      {occ.stdname && (
                        <td>
                          {occ.stdname} {occ.last_name}
                        </td>
                      )}
                      <td>
                        {occ.name}-
                        {occ.weekdays.map(
                          (e, i, arr) =>
                            `${daysOfWeek[e]}${i === arr.length - 1 ? "" : ","}`
                        )}
                      </td>
                      <td>
                        {Number(occ.value).toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
      </Form>
      <Row className="mb-3">
        <Col>
          <Button variant="link" size="lg" onClick={createFile}>
            Baixar extrato
          </Button>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <div className="d-grid gap-2">
            {userType === "S" && (
              <Button
                variant="primary"
                size="lg"
                style={{ backgroundColor: "#00a3f4" }}
                // onClick={createFile}
              >
                Solicitar recibo
              </Button>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Extrato;
